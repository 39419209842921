<template>
  <div id="RolesRegister">
    <button
      @click="addRol()"
      style="background-color: #1d425e; color: white"
      class="btn mb-3"
    >
      Registrar Rol
    </button>
    <div>
      <b-modal
        scrollable
        centered
        v-model="show"
        title="Registrar Rol"
      >
        <b-container fluid>
          <!--Contenido modal-->
          <form>
            <AlertError ref="error" />
            <div class="form-group">
              <label for="exampleInputEmail1">Nombre</label>
              <input
                type="text"
                class="form-control"
                placeholder="Ingrese nombre"
                v-model="data.name"
                required
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Descripción</label>
              <b-form-textarea
                class="form-control"
                placeholder="Ingrese descripción"
                v-model="data.description"
                required
              />
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="saveAddItem(data)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Enviar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import AlertError from "../../components/AlertError.vue";
export default {
  name: "RolesRegister",
  components: { AlertError },
  data() {
    return {
      show: false,
      data: {
        name: "",
        description: "",
      },
    };
  },
  methods: {
    exit() {
      this.show = false;
    },
    addRol() {
      (this.data = {
        name: "",
        description: "",
      }),
        (this.show = true);
    },
    async saveAddItem() {
      await this.$axios
        .post("/security/role/register", this.data, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.show = false;
          this.$message.success(res.data.content);
          //Inicializar componente padre
          this.$parent.initialize();
        })
        .catch((e) => {
          console.log(e);
          this.$refs.error.showAlert(e.response.data.content);
        });
    },
  },
};
</script>
<style>
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>