<template>
  <div id="dashboard">
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h1><b-icon icon="person-lines-fill" /> Gestión de roles</h1>
        </div>
        <div class="card-body">
          <div class="container col-12">
            <div>
              <RolesRegister />
              <b-tabs
                class="card"
                card
                fill
              >
                <b-tab
                  v-for="(item, index) in desserts"
                  :key="index"
                  :title="item.name"
                >
                  <b-table
                    striped
                    responsive
                    stacked="lg"
                    :items="item.rolesType"
                    :fields="fields"
                  >
                    <template #cell(state)="row">
                      <b-badge pill :variant="returnColor(row.item.state)">{{
                        returnData(row.item.state)
                      }}</b-badge>
                    </template>
                    <template #cell(actions)="row">
                      <b-icon-pencil-square
                        type="button"
                        size="sm"
                        class="mx-1"
                        @click="dateItem(row.item)"
                      ></b-icon-pencil-square>

                      <b-icon-trash
                        v-show="item.type === 'D'"
                        type="button"
                        size="sm"
                        class="mx-1"
                        @click="modalDelete(row.item)"
                      ></b-icon-trash>
                    </template> </b-table
                ></b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--modal modificar-->
    <div>
      <b-modal scrollable centered v-model="show" title="Editar Rol">
        <b-container fluid>
          <form>
            <AlertError ref="error" />
            <div class="form-group">
              <label for="exampleInputEmail1">Nombre</label>
              <input
                type="text"
                class="form-control"
                placeholder="Ingrese su nombre"
                v-model="data.name"
                required
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Descripción</label>
              <b-form-textarea
                class="form-control"
                placeholder="Ingrese descripción"
                v-model="data.description"
                required
              />
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="saveEditItem(data)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Enviar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
    <!--modal eliminar-->
    <div>
      <b-modal centered v-model="showDelete" title="Mensaje">
        <b-container fluid>
          <!--Contenido modal-->
          <h5 class="text-center">
            ¿Seguro que desea eliminar a <b>{{ data.name }}</b
            >?
          </h5>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="deleteItem(dataDelete)"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Aceptar
            </button>
            <button
              type="button"
              @click="exitDelete()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import RolesRegister from "../File-role/Roles-register.vue";
import AlertError from "../../components/AlertError.vue";
export default {
  components: { RolesRegister, AlertError },
  data: () => ({
    selected: "",
    dataDelete: "",
    show: false,
    showDelete: false,
    data: {
      id: -1,
      name: "",
      description: "",
      state: "",
    },
    desserts: [],
    fields: [
      { key: "name", label: "Nombre" },
      { key: "state", label: "Estado" },
      { key: "actions", label: "Acciones" },
    ],
  }),
  created() {
    this.initialize();
  },

  methods: {
    returnData(data) {
      if (data === "A") return "Activo";
      else if (data === "I") return "Inactivo";
    },
    returnColor(data) {
      if (data === "A") return "success";
      else if (data === "I") return "danger";
    },
    modalDelete(data) {
      this.showDelete = true;
      this.dataDelete = data;
      this.data.name = data.name;
    },
    exitDelete() {
      this.showDelete = false;
      this.data.name = "";
    },
    exit() {
      this.show = false;
    },
    async initialize() {
      this.selected = "circular";
      await this.$axios
        .get("/security/role", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          var aux = [];
          for (var i in res.data) {
            var obj = {
              type: res.data[i].type,
              name: res.data[i].name,
              rolesType: res.data[i].rolesType,
            };
            aux.push(obj);
          }
          this.desserts = aux;
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async dateItem(item) {
      this.show = true;
      let res = await this.$axios.get("/security/edit-role/" + item.id, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      });
      this.data = res.data;
    },
    async saveEditItem(item) {
      if (item.id != -1) {
        try {
          await this.$axios
            .post("/security/edit-role", this.data, {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            })
            .then((res) => {
              this.$message.success(res.data.content);
              this.show = false;
              this.initialize();
            })
            .catch((e) => {
              this.$refs.error.showAlert(e.response.data.content);
            });
        } catch (e) {
          console.log(e);
        }
      }
    },
    async deleteItem(item) {
      this.showDelete = false;
      if (item != "") {
        try {
          await this.$axios
            .get("/security/delete-role/" + item.id + "/", {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            })
            .then((res) => {
              this.$message.success(res.data.content);
              this.initialize();
            });
        } catch (e) {
          this.$message.error("Error!");
          console.log(e);
        }
      }
    },
  },
};
</script>

<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
.nav-tabs .nav-link.active {
  background-color: rgb(243, 243, 243);
  border-color: rgb(243, 243, 243);
  color: black;
  font-weight: bolder;
}
.nav-tabs .nav-link {
  color: white;
}
</style>